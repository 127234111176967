import { SchemaStoryResponseDto } from '@jam/api-sdk';
import Vapi from '@vapi-ai/web';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
	useCreateSessionMutation,
	useDeleteSessionMutation,
} from '../../../redux/api/sessionApi';
import { useAppSelector } from '../../../redux/hooks';
import {
	selectCurrentCallStatus,
	setBotIsSpeaking,
	setCurrentCallStatus,
} from '../../../redux/slice';
import soundPhoneCallEnd from '../../call/assets/phone_call_end.mp3';
import soundPhoneCallPickUp from '../../call/assets/phone_call_pick_up.mp3';
import soundPhoneCall from '../../call/assets/phone_call_start.mp3';
import { CallStatus } from '../components/CallStatus';

const vapiToken = process.env.REACT_APP_VAPI_PUBLIC_KEY;
const SERVER_URL = process.env.REACT_APP_ASSISTANT_SERVER_URL;

export const useVapi = ({
	userId,
	story,
}: {
	story: SchemaStoryResponseDto;
	userId: string | undefined;
}) => {
	const [vapi] = useState(new Vapi(vapiToken as string));
	const dispatch = useDispatch();
	const [sessionId, setSessionId] = useState<string | null>(null);
	const [deleteSession] = useDeleteSessionMutation();
	const [createCall] = useCreateSessionMutation();
	const [error, setError] = useState<string | null>(null);
	const [startTime, setStartTime] = useState<number | null>(null);
	const [callDurationSec, setCallDurationSec] = useState<number | null>(null);
	const callStatus = useAppSelector(selectCurrentCallStatus);

	const soundStartCall = new Audio(soundPhoneCall);
	const soundEndCall = new Audio(soundPhoneCallEnd);
	const soundPickUp = new Audio(soundPhoneCallPickUp);

	useEffect(() => {
		if (callStatus !== CallStatus.finished) return;

		setCallDurationSec(
			startTime ? Math.floor((Date.now() - startTime) / 1000) : null
		);
		setStartTime(null);
	}, [callStatus, startTime]);

	// hook into Vapi events
	useEffect(() => {
		dispatch(setCurrentCallStatus(CallStatus.notStarted));

		vapi.on('call-start', () => {
			setStartTime(Date.now());
			setCallDurationSec(null);
			dispatch(setCurrentCallStatus(CallStatus.started));
			dispatch(setBotIsSpeaking(false));
			setError(null);

			if (story.situation.conversation_type == 'call') {
				void soundPickUp.play();
			}
		});

		vapi.on('call-end', () => {
			if (story.situation.conversation_type === 'call') {
				void soundEndCall.play();
			}
			dispatch(setBotIsSpeaking(false));
			dispatch(setCurrentCallStatus(CallStatus.finished));
		});

		vapi.on('speech-start', () => {
			dispatch(setBotIsSpeaking(true));
		});

		vapi.on('speech-end', () => {
			dispatch(setBotIsSpeaking(false));
		});

		vapi.on('error', (err: Error) => {
			setError(err.message);
			dispatch(setCurrentCallStatus(CallStatus.finished));
			dispatch(setBotIsSpeaking(false));
		});

		// we only want this to fire on mount
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [vapi]);

	const start = useCallback(async () => {
		try {
			if (story.situation.conversation_type == 'call') {
				void soundStartCall.play();
			}

			dispatch(setCurrentCallStatus(CallStatus.loading));

			const payload = await createCall({
				storyId: story.id,
			}).unwrap();

			const assistantReferenceId: string = payload.call.assistantReferenceId;

			await vapi.start(assistantReferenceId, {
				metadata: {
					sessionId: payload.id,
					storyId: story.id,
					userId,
					sagaId: story.saga.id,
				},
				serverUrl: SERVER_URL,
			});

			setSessionId(payload.id);
		} catch (err) {
			dispatch(setCurrentCallStatus(CallStatus.finished));
			setError((err as Error).message);
		}
	}, [story]);

	const stop = () => {
		vapi.stop();
		dispatch(setCurrentCallStatus(CallStatus.finished));

		setError(null);
		dispatch(setBotIsSpeaking(false));
	};

	const cleanup = async (): Promise<void> => {
		if (
			!sessionId ||
			callStatus === CallStatus.started ||
			callStatus === CallStatus.loading
		) {
			return;
		}
		await deleteSession(sessionId);
		setSessionId(null);
	};

	return {
		start,
		stop,
		deleteSession: cleanup,
		sessionId,
		error,
		callDurationSec,
	};
};

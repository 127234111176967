import { Flex, useDisclosure, useToast } from '@chakra-ui/react';
import { SchemaStoryResponseDto } from '@jam/api-sdk';
import { useAuthInfo } from '@propelauth/react';
import { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useStoryStatus } from '../../../common/hooks/useStoryStatus';
import { useAppSelector } from '../../../redux/hooks';
import { selectCurrentCallStatus } from '../../../redux/slice';
import { useNextStep } from '../hooks/useNextStep';
import { useVapi } from '../hooks/useVapi';
import { CallButton } from './CallButton';
import { CallStatus } from './CallStatus';
import CallTooShortDialog from './CallTooShortDialog';
import { NextStory } from './NextStory';

type CallerButtonsProps = {
	numberOfRounds: number;
	story: SchemaStoryResponseDto;
	onFinishCall: () => Promise<void>;
};

export const CallerButtons = ({
	story,
	numberOfRounds,
	onFinishCall,
}: CallerButtonsProps) => {
	const { t } = useTranslation(['call', 'story']);
	const nextStep = useNextStep();
	const { user } = useAuthInfo();
	const callStatus = useAppSelector(selectCurrentCallStatus);
	const storyStatus = useStoryStatus(story.id);
	const { isOpen, onOpen, onClose } = useDisclosure();
	const toast = useToast();
	const { start, stop, deleteSession, error, callDurationSec } = useVapi({
		story,
		userId: user?.userId,
	});

	const finishCallProcedure = useCallback(async () => {
		onClose();
		await onFinishCall();
	}, []);

	const onCallerClickHandler = useCallback(() => {
		if (callStatus === CallStatus.notStarted) {
			void start();
		} else if (callStatus === CallStatus.started) {
			stop();
		} else if (callStatus === CallStatus.finished) {
			// user can start a new call
			void start();
		}
	}, [callStatus]);

	const onCancelFeedbackGenerationHandler =
		useCallback(async (): Promise<void> => {
			onClose();
			await deleteSession();
		}, []);

	useEffect(() => {
		if (callStatus !== CallStatus.finished || callDurationSec === null) return;

		if (callDurationSec >= 20) {
			void finishCallProcedure();
		} else {
			onOpen();
		}
	}, [callStatus, callDurationSec]);

	useEffect(() => {
		if (!error) return;

		toast({
			title: 'Error',
			description: t('call_error_text'),
			status: 'error',
		});
	}, [error]);

	const showNextStory =
		nextStep &&
		callStatus !== CallStatus.started &&
		callStatus !== CallStatus.loading;

	let primaryCta: 'next' | 'current' = 'current';
	if (storyStatus === 'COMPLETED' && showNextStory) {
		primaryCta = 'next';
	}

	if (nextStep && nextStep.action === 'back') {
		primaryCta = 'current';
	}

	return (
		<Flex flexShrink={0} gap="2" alignItems={'center'} h="fit-content">
			<CallTooShortDialog
				isOpen={isOpen}
				onCancelFeedback={() => {
					void onCancelFeedbackGenerationHandler();
				}}
				onGenerateFeedback={() => void finishCallProcedure()}
			/>

			{showNextStory && (
				<NextStory isPrimary={primaryCta === 'next'} storyId={story.id} />
			)}

			<CallButton
				firstTime={numberOfRounds < 1}
				isPrimary={primaryCta === 'current'}
				onCallerClick={onCallerClickHandler}
			/>
		</Flex>
	);
};
